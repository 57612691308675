import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of, Observable } from 'rxjs';

import { FirebaseService } from '../../services/firebase.service';
import { AccountService } from './account/account.service';
import { SYNDICATE } from '../../interfaces/syndicate';
import { SYNDICATE_CATEGORY } from '../../interfaces/syndicate_category';

import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: "root"
})
export class SyndicateService {
  private _isCreateSyndicatePending = false;
  private _syndicate: SYNDICATE = {} as SYNDICATE;
  private _stepCount: number = 1;
  private _createSyndicateStep = 'personal-info';
  private _selectedCategory: SYNDICATE_CATEGORY;
  private _syndicates: SYNDICATE[] = [];
  private _isLoading = true;
  private _syndicateCategories: SYNDICATE_CATEGORY[] = [];
  private _tags: any[] = [];

  constructor(
    private firebaseService: FirebaseService,
    private toastr: ToastrService,
    private router: Router,
    private accountService: AccountService
  ) {}

  /**
   * [Get syndicates]
   * @author Sachin Jagtap
   * @task SM-46
   */
  public getSyndicates(limit: number): Observable<any[]> {
    if (this._syndicates.length > 0) {
      return of(this._syndicates.slice(0, limit));
    } else {
      return this.firebaseService.getCollection('syndicates', limit);
    }
  }

  /**
   * [Get syndicate categories]
   * @author Sachin Jagtap
   * @task SM-58
   */
  public getSyndicateCategories(): Observable<any[]> {
    return this.firebaseService.getCollection('syndicate_categories');
  }

  /**
   * [Open create syndicate page based on step]
   * @param step [current syndicate step]
   * @param currentCount [current count]
   * @author Sachin Jagtap
   * @task SM-58
   */
  public setSyndicateStep(step: string, currentCount?: number) {
    this.createSyndicateStep = step;
    if (currentCount) {
      this.stepCount = currentCount;
    } else {
      this.stepCount++;
    }
  }

  /**
   * [Add Syndicate category]
   * @param syndicateCategory [custom syndicateCategory created by user]
   * @author Sachin Jagtap
   * @task SM-58
   */
  public addSyndicateCategory(syndicateCategory: SYNDICATE_CATEGORY) {
    this.firebaseService.addModel('syndicate_categories', syndicateCategory);
  }

  /**
   * [Add Syndicate]
   * @param syndicate [Syndicate created by user]
   * @author Sachin Jagtap
   * @task SM-58
   */
  public addSyndicate(navigationUrl = 'home') {
    this.isLoading = true;
    this.syndicate.user_id = this.accountService.user_id;

    this.firebaseService.addModel('syndicates', this.syndicate).then((res: any) => {
      this.toastr.success('Syndicate Created Successfully', 'Success');
      this.isCreateSyndicatePending = false;
      this.isLoading = false;
      this.router.navigateByUrl(navigationUrl);
    })
    .catch((errors: any) => {
      console.log('errors', errors);
      this.isLoading = false;
      this.toastr.error(errors.message, 'Error');
    });
  }

  /**
   * [Get syndicates by syndicate category]
   * @author Sachin Jagtap
   * @task SM-61
   */
  public getSyndicatesBySyndicateCategoryId(syndicateCategoryId: string, limit: number = 100): Observable<any[]> {
    return this.firebaseService.getCollectionById('syndicates', 'syndicate_category_id', syndicateCategoryId, limit);
  }

  /**
   * [Fetch tags by syndicateCategoryId]
   * @author Sachin Jagtap
   * @task SM-70
   */
  public getTagsById(syndicateCategoryId: string): Observable<any[]> {
    return this.firebaseService.getCollectionById('tags', 'syndicateCategoryId', syndicateCategoryId);
  }

  /**
   * [Tag added]
   * @author Sachin Jagtap
   * @task SM-70
   */
  public addTags(model: any) {
    this.firebaseService.addModel('tags', model).catch((errors: any) => {
      console.log('errors', errors);
    });
  }

  /**
   * [Tag updated]
   * @author Sachin Jagtap
   * @task SM-70
   */
  public updateTags(id: string, tags: string[]) {
    this.firebaseService.updateModelById('tags', id, tags);
  }

  /**
   * [Get tags]
   * @author Sachin Jagtap
   * @task SYN-31
   */
  public getTags(): Observable<any[]> {
    return this.firebaseService.getCollection('tags');
  }

  // Getters and Setters
  public get isCreateSyndicatePending(): boolean {
    return this._isCreateSyndicatePending;
  }

  public set isCreateSyndicatePending(isCreateSyndicatePending: boolean) {
    this._isCreateSyndicatePending = isCreateSyndicatePending;
  }

  public get syndicate(): SYNDICATE {
    return this._syndicate;
  }

  public set syndicate(syndicate: SYNDICATE) {
    this._syndicate = syndicate;
  }

  public get stepCount(): number {
    return this._stepCount;
  }

  public set stepCount(stepCount: number) {
    this._stepCount = stepCount;
  }

  public get createSyndicateStep(): string {
    return this._createSyndicateStep;
  }

  public set createSyndicateStep(createSyndicateStep: string) {
    this._createSyndicateStep = createSyndicateStep;
  }

  public get selectedCategory(): SYNDICATE_CATEGORY {
    return this._selectedCategory;
  }

  public set selectedCategory(selectedCategory: SYNDICATE_CATEGORY) {
    this._selectedCategory = selectedCategory;
  }

  public get syndicates(): SYNDICATE[] {
    return this._syndicates;
  }

  public setSyndicates(oldSyndicates: SYNDICATE[], newSyndicates: SYNDICATE[] = []) {
    this._syndicates = [
      ...oldSyndicates,
      ...newSyndicates
    ];
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }

  public set isLoading(isLoading) {
    this._isLoading = isLoading;
  }

  public get syndicateCategories(): SYNDICATE_CATEGORY[] {
    return this._syndicateCategories;
  }

  public set syndicateCategories(syndicateCategories: SYNDICATE_CATEGORY[]) {
    this._syndicateCategories = syndicateCategories;
  }

  public get tags(): any[] {
    return this._tags;
  }

  public set tags(tags: any[]) {
    this._tags = tags;
  }
}
