import { Injectable } from '@angular/core';
import { Auth, Hub } from 'aws-amplify';
import { Subject, Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';

import { FirebaseService } from '../../../services/firebase.service';
import * as firebase from 'firebase/app';

@Injectable()
export class AccountService {

    private onAuthStateChange = new Subject<string>();
    private _userInfo: {[key: string]: any} = {};
    private _user_address: any = {};
    private _user_id: string;
    private _user_credential: object = {};

    constructor(
        private firebaseService: FirebaseService,
        private toastr: ToastrService
    ) {

        // This will listen to auth and send the event, the event can be singIn, signOut, cognitoHostedUI and customOAuthState
        // Here is the quick link for the aws amplify documentation - https://aws-amplify.github.io/docs/js/authentication#oauth-and-hosted-ui
        Hub.listen("auth", ({ payload: { event } }) => { this.onAuthStateChange.next(event); });
    }

    getCurrentAuthState(): Observable<string> {
        return this.onAuthStateChange.asObservable();
    }

    /**
     * This function will call Auth aws amplify module to do the login or sign up
     * This will redirect the app to the amazon hosted ui and the way Auth module determines that is by looking at the configuration of the Auth.
     * If it has oauth property with the domain, it will redirect the app to that domain. In our case temporarily we are using Amazon Hosted UI which handles user sign in, sign up,
     * federated sign in (Like Google, Facebook, Custom)
     */
    login() { Auth.federatedSignIn(); }

    /**
     * This function will logout the user from the current session of the amazon hosted ui. It will remove all the tokenw which Auth module saves when oauth returns the code
     */
    signOut() { Auth.signOut(); }

    async getCurrentUser() { return await Auth.currentAuthenticatedUser(); }

    /**
     * [Create User]
     * @param email [email of user]
     * @param password [password of user]
     * @author Sachin Jagtap
     * @task SM-58
     */
    public createUser(email: string, password: string) {
        return this.firebaseService.createUser(email, password);
    }

    /**
     * [Add User]
     * @param user [UserInfo of user]
     * @author Sachin Jagtap
     * @task SM-58
     */
    public addUserInfo() {
        return this.addUser().pipe(
            switchMap((response) => {
                this._user_id = response.id;
                this._user_address.user_id = this._user_id;
                return this.addUserAddress();
            })
        );
    }

    private addUser() {
        return from(this.firebaseService.addModel('users', this.userInfo));
    }

    private addUserAddress() {
        return from(this.firebaseService.addModel('user_addresses', this.user_address));
    }

    public getUser(email: string): Observable<any> {
        return this.firebaseService.getuser(email);
    }

    public register(email: string, password: string) {
        return firebase.auth.EmailAuthProvider.credential(email, password);
    }

    public linkUser(credential: any) {
        return firebase.auth().currentUser.linkAndRetrieveDataWithCredential(credential);
    }

    /**
     * [Get address of user by id]
     * @author Sachin Jagtap
     * @task SM-72
     */
    public getUserAddress() {
        this.firebaseService.getUserAddress(this._user_id).subscribe(
            (response: any[]) => {
                this.user_address = response;
            },
            (errors) => {
                console.log('errors', errors);
            }
        )
    }

    // Getter and Setter
    public set userInfo(userInfo: {[key: string]: any}) {
        this._userInfo = userInfo;
    }

    public get userInfo() {
        return this._userInfo
    }

    public set user_address(user_address: any) {
        this._user_address = user_address;
    }

    public get user_address() {
        return this._user_address;
    }

    public set user_id(user_id: string) {
        this._user_id = user_id;
    }

    public get user_id() {
        return this._user_id;
    }

    public set userCredential(userCredential: any) {
        this._user_credential = userCredential;
    }
}
