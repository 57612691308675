import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { SyndicateService } from '../../common/services/syndicate.service';

@Component({
    selector: "main-navigation",
    template: require('./main-navigation.component.html')
})
export class MainNavigationComponent {
    public showMenu = false;
    public hoveredSyndicate: BehaviorSubject<string> = new BehaviorSubject('');
    @Input() activeSyndicate: string = '';

    constructor(
        public syndicateService: SyndicateService
    ) {}

    /**
     * [Dont expand panel if clicked on text]
     * @param matExpansionPanel [Reference of expansion panel]
     * @param event [click event fired]
     * @author Sachin Jagtap
     * @task SM-51
     */
    public onExpansionPanelClicked(matExpansionPanel: any, event: any) {
        event.stopPropagation();
        if (!this.isExpansionIndicator(event.target)) {
            matExpansionPanel.close(); // Here's the magic
        }
    }

    /**
     * [Checking if target is expanded]
     * @param target [Currently clicked panel]
     * @author Sachin Jagtap
     * @task SM-51
     */
    private isExpansionIndicator(target: any): boolean {
        const expansionIndicatorClass = 'mat-expansion-indicator';

        return (target.classList && target.classList.contains(expansionIndicatorClass) );
    }

    /**
     * [Set hover menu]
     * @param syndicate [current syndicate]
     * @task SM-54
     */
    public setHoverMenu(syndicate: string) {
        this.hoveredSyndicate.next(syndicate);
    }
}