import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
    private firebaseAuth: AngularFireAuth,
    private db: AngularFirestore
  ) {}

  /**
   * [Sign in anonymously function]
   * @author Sachin Jagtap
   * @task SM-58
   */
  public async signInAnonymously() {
    return await this.firebaseAuth.signInAnonymously();
  }

  /**
   * [Create User]
   * @author Sachin Jagtap
   * @task SM-58
   */
  public createUser(email: string, password: string) {
    return this.firebaseAuth.createUserWithEmailAndPassword(email, password)
  }

  /**
   * [Get Collection by collectionName]
   * @author Sachin Jagtap
   * @task SM-58
   */
  public getCollection(collectionName: string, limit: number = 100) {
    return this.db.collection(`${collectionName}`, ref => ref.limit(limit))
    .valueChanges({ idField: 'id' });
  }

  /**
   * [Add Model]
   * @author Sachin Jagtap
   * @task SM-58
   */
  public async addModel(collectionName: string, model: any) {
    return await this.db.collection(`${collectionName}`).add(model);
  }

  public getuser(email: string) {
    return this.db.collection('users', ref => ref.where('email', '==', email).limit(1)).valueChanges({ idField: 'id' });
  }

  /**
   * [Get Collection by collectionName]
   * @author Sachin Jagtap
   * @task SM-58
   */
  public getCollectionById(collectionName: string, key: string, id: string, limit: number = 100) {
    return this.db.collection(`${collectionName}`, ref => ref.where(key, '==', id).limit(limit))
    .valueChanges({ idField: 'id' });
  }

  /**
   * [Update Model]
   * @author Sachin Jagtap
   * @task SM-58
   */
  public async updateModelById(collectionNameString : string, id: string, data: any) {
    this.db.doc(`${collectionNameString }/${id}`).update({ tags: data});
  }

  public getUserAddress(userId: string) {
    return this.db.collection('user_addresses', ref => ref.where('user_id', '==', userId).limit(1)).valueChanges();
  }
}