// Venture (Third Party) Specific Imports
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes }   from '@angular/router';

// Third party modules
import { ToastrModule } from 'ngx-toastr';

import {
	MatButtonModule,
	MatExpansionModule,
	MatIconModule,
	MatInputModule,
	MatListModule,
	MatMenuModule,
} from '@angular/material';

// Application (Numeras) Specific Component
import { AppComponent } from './app.component';
import { MainNavigationComponent } from './header/main-navigation/main-navigation.component';
import { MiniNavigationComponent } from './header/mini-navigation/mini-navigation.component';
import { AppFooterComponent } from './footer/app.footer.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';

// Services Import
import { ServicesModule } from './common/services/services.module';

const appRoutes: Routes = [
	{
		path: 'numeras',
		// loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule)
		loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\landing-page\\landing-page.module')['LandingPageModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) }
	},
	// {
	// 	path: 'home',
	// 	loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\home\\home.module')['HomeModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) }
	// },
	// {
	// 	path: 'syndicate',
	// 	loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\syndicate\\syndicate.module')['SyndicateModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) }
	// },
	// {
	// 	path: 'catalog',
	// 	loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\catalog\\catalog.module')['CatalogModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) }
	// },
	// {
	// 	path: 'account',
	// 	loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('.\\account\\account.module')['AccountModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) }
	// },
	// TODO: Not needed, remove when uncommenting these routes
	// { path: '', redirectTo: '/home', pathMatch: 'full' }
	{ path: '', redirectTo: '/numeras', pathMatch: 'full' }
];

const firebaseConfig = {
	apiKey: 'AIzaSyCpU3aCW4Jvo_ubhFBMBkjl8nOFfCxjmOg',
	authDomain: 'numeras-dev.firebaseapp.com',
	databaseURL: 'https://numeras-dev.firebaseio.com',
	projectId: 'numeras-dev',
	storageBucket: 'numeras-dev.appspot.com',
	messagingSenderId: '154414453463'
};

@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		FlexLayoutModule,
		ServicesModule.forRoot(),
		RouterModule.forRoot(appRoutes, { useHash: true }),
		ToastrModule.forRoot(),
		MatButtonModule,
		MatExpansionModule,
		MatInputModule,
		MatIconModule,
		MatListModule,
		MatMenuModule,
		AngularFireModule.initializeApp(firebaseConfig),
		AngularFirestoreModule,
		AngularFireAuthModule
	],
	declarations: [
		AppComponent,
		MainNavigationComponent,
		MiniNavigationComponent,
		AppFooterComponent
	],
	bootstrap: [ AppComponent ]
})
export class AppModule { }