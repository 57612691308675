// IE9, IE10 and IE11 requires all of the following polyfills.
import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/parse-int';
import 'core-js/es6/parse-float';
import 'core-js/es6/number';
import 'core-js/es6/math';
import 'core-js/es6/string';
import 'core-js/es6/date';
import 'core-js/es6/array';
import 'core-js/es6/regexp';
import 'core-js/es6/map';
import 'core-js/es6/weak-map';
import 'core-js/es6/set';
import 'classlist-polyfill';
if (!Element.prototype.matches) {

    // @ts-ignore
    Element.prototype.matches = Element.prototype.msMatchesSelector;
}

(window as any).global = window;

import 'reflect-metadata';
import 'zone.js';

import { enableProdMode } from '@angular/core';



// Angular require to import hammerjs in order to leverage their animation completely. That means there are some Animation functionality which internally use hammerjs
import 'hammerjs';



// The browser platform with a compiler
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// if URL doesn't contain 'localhost', i.e., not running on a development machine, enable production mode - we don't need dev to be re-checking templates
if (window.location.host !== 'localhost') enableProdMode();


import Amplify from 'aws-amplify';


Amplify.configure({
    Auth: {

        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: 'us-east-2:9dca06fc-c99e-4ba1-9efc-8eda96623091',

        // REQUIRED - Amazon Cognito Region
        // region: 'Regions.US_EAST_2',
        region: 'us-east-2',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-east-2_7vZQqLuTQ',

        // OPTIONAL - Amazon Cognito Web Client ID
        userPoolWebClientId: '7f8uiq0a6rvekvd99rjit0tete',

        oauth: {
            domain: 'numeras-app.auth.us-east-2.amazoncognito.com',
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'http://localhost:4200/',
            redirectSignOut: 'http://localhost:4200/',
            responseType: 'code'
        }
    },

    // Added analytics to be disabled because for some reason when we get redirected from oauth2 we get invalid client id, invalid credential error
    Analytics: {
        disabled: true
    }
});

import './../../styles/sass/app-core.scss';

// bootstrap the main application - compile JIT (Just In Time) and launch
import { AppModule } from '../app.module';

platformBrowserDynamic().bootstrapModule(AppModule);
