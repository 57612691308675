import { Component, ChangeDetectorRef } from '@angular/core';
import { AccountService } from '../../common/services/account/account.service';

/**
 * Mini Navigation Component
 */
@Component({
    selector: "mini-navigation",
    template: require('./mini-navigation.component.html')
})

export class MiniNavigationComponent {

    private isLoggedIn: boolean = false;

    constructor(private accountService: AccountService, private _ref: ChangeDetectorRef) {

        this.accountService.getCurrentAuthState().subscribe( (data) => {

            this.isLoggedIn = (data === 'signIn' || data === 'cognitoHostedUI') ? true : false;

            // Somehow angular does not detect the changes happening in rxjs observabkle
            // so we have to manually detect the changes. There is no way angular can detect rxjs observalble changes
            // TODO - We need to research more so that we do not trigger angular cycle manually which I think is not a good practice.
            this._ref.detectChanges();
        });
    }


    async ngAfterViewInit(): Promise<void> {

        // There are two ways we need to make isLogged flag false or true, 1 during auth and 2 during refresh of the page, if auth module in aws amplify has
        // current user we need to make sure that we show user that they are logged in
        // This code is giving console error "not authenticated", we need to make sure that we see what is going on, may be a timeout issue.
        this.isLoggedIn = await this.accountService.getCurrentUser() ? true : false;
    }
}