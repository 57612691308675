import { NgModule, ModuleWithProviders } from '@angular/core';

import { AccountService } from './account/account.service';

@NgModule({})
export class ServicesModule {
	static forRoot(): ModuleWithProviders {
		return {
            ngModule: ServicesModule,
            providers: [ AccountService ]
		};
	}
}