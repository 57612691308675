import { Component, OnInit } from '@angular/core';
import { SyndicateService } from './common/services/syndicate.service';
import { FirebaseService } from './services/firebase.service';
import { Router, NavigationStart } from '@angular/router';

/**
 * Main App Component
 */
@Component({
	
	selector: 'numeras-app',
	template: require('./app.component.html')
})
export class AppComponent implements OnInit {
	public isAccountPage = false;
	public activeSyndicate: string;

	constructor(
		private syndicateService: SyndicateService,
		private firebaseService: FirebaseService,
		private router: Router
	) {}

	ngOnInit() {
		this.firebaseService.signInAnonymously()
		.then((user: any) => {
			// console.log('user', user);
			this.syndicateService.isLoading = false;
		})
		.catch((error: any) => console.log(error));

		// afterwards move social images to seperate component
		this.router.events.subscribe(event => {
			if(event instanceof NavigationStart) {
				// for social images
				if (event.url.startsWith('/account')) {
					this.isAccountPage = true;
				} else {
					this.isAccountPage = false;
				}
				if (
					event.url.startsWith('/syndicate') ||
					(event.url.startsWith('/account') && this.syndicateService.isCreateSyndicatePending)
				) {
					this.activeSyndicate = 'createSyndicate';
				} else  if (
					event.url.startsWith('/catalog')
				) {
					this.activeSyndicate = 'joinSyndicate';
				} else {
					this.activeSyndicate = '';
				}
			}
		});
	}
}
